import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols);
} return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); });
} return target; }
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { helmetJsonLdProp } from 'react-schemaorg';
import Article from '../components/core/article';
import getSiteMetadata from '../util/get-site-metadata';
import { makeBlog, makeRoot } from '../util/ld-json';
import { jsx as ___EmotionJSX } from "@emotion/react";
var BlogPage = function BlogPage(_ref) { var _data$allMdx$nodes$0$; var data = _ref.data; return ___EmotionJSX(Article, { pageTitle: "My Blog Posts", headerImg: (_data$allMdx$nodes$0$ = data.allMdx.nodes[0].frontmatter) === null || _data$allMdx$nodes$0$ === void 0 ? void 0 : _data$allMdx$nodes$0$.headerImg }, ___EmotionJSX(Helmet, { script: [helmetJsonLdProp(makeRoot(makeBlog(getSiteMetadata().siteUrl, "blogs", data.allMdx.nodes.map(function (d) { var _d$frontmatter; return _objectSpread(_objectSpread({}, d), {}, { frontmatter: _objectSpread(_objectSpread({}, d.frontmatter), {}, { heroImage: (_d$frontmatter = d.frontmatter) === null || _d$frontmatter === void 0 ? void 0 : _d$frontmatter.headerImg }) }); }))))] }), ___EmotionJSX("p", null, "My posts will go here! "), data.allMdx.nodes.map(function (node) { return ___EmotionJSX("article", { key: node.id }, ___EmotionJSX("h2", null, node.fields.title), ___EmotionJSX("p", null, "Posted: ", node.fields.date), ___EmotionJSX(Link, { to: "/" + node.fields.slug }, "Here")); })); };
var query = "4292358497";
export default BlogPage;
