import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols);
} return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); });
} return target; }
import { getImage } from 'gatsby-plugin-image';
var jsonStringifySorted = function jsonStringifySorted(data, optsIn) {
    if (optsIn === void 0) {
        optsIn = {};
    }
    var opts;
    if (typeof optsIn === "function")
        opts = { cmp: optsIn };
    else
        opts = optsIn;
    var cycles = typeof opts.cycles === "boolean" ? opts.cycles : false;
    var cmp = opts.cmp && function (f) { return function (node) { return function (a, b) { var aObj = { key: a, value: node[a] }; var bObj = { key: b, value: node[b] }; return f(aObj, bObj); }; }; }(opts.cmp);
    var seen = [];
    var stringify = function stringify(node) {
        if (node && node.toJSON && typeof node.toJSON === "function") { // eslint-disable-next-line no-param-reassign
            node = node.toJSON();
        }
        if (node === undefined)
            return "";
        if (typeof node === "number")
            return Number.isFinite(node) ? "" + node : "null";
        if (typeof node !== "object")
            return JSON.stringify(node);
        var i;
        var out;
        if (Array.isArray(node)) {
            out = "[";
            for (i = 0; i < node.length; i += 1) {
                if (i)
                    out += ",";
                out += stringify(node[i]) || "null";
            }
            return out + "]";
        }
        if (node === null)
            return "null";
        if (seen.indexOf(node) !== -1) {
            if (cycles)
                return JSON.stringify("__cycle__");
            throw new TypeError("Converting circular structure to JSON");
        }
        var seenIndex = seen.push(node) - 1;
        var keys = Object.keys(node).sort(cmp && cmp(node));
        out = "";
        for (i = 0; i < keys.length; i += 1) {
            var key = keys[i];
            var value = stringify(node[key]);
            if (value) {
                if (out)
                    out += ",";
                out += JSON.stringify(key) + ":" + value;
            }
        }
        seen.splice(seenIndex, 1);
        return "{" + out + "}";
    };
    return stringify(data);
};
export var makePerson = function makePerson(_ref) { var name = _ref.name, url = _ref.url; return { '@type': "Person", name: name, url: url }; };
export var makeArticle = function makeArticle(siteUrl, _ref2) { var _frontmatter$heroImag, _frontmatter$heroImag2, _img$images, _img$images$fallback, _img$images2, _img$images2$fallback; var frontmatter = _ref2.frontmatter, fields = _ref2.fields; var img = getImage(frontmatter === null || frontmatter === void 0 ? void 0 : (_frontmatter$heroImag = frontmatter.heroImage) === null || _frontmatter$heroImag === void 0 ? void 0 : (_frontmatter$heroImag2 = _frontmatter$heroImag.childImageSharp) === null || _frontmatter$heroImag2 === void 0 ? void 0 : _frontmatter$heroImag2.gatsbyImageData); var mainEntityOfPage = { '@type': "WebPage", url: siteUrl + "/" + fields.slug }; return { '@type': "Article", mainEntityOfPage: mainEntityOfPage, author: frontmatter !== null && frontmatter !== void 0 && frontmatter.author ? makePerson(frontmatter.author) : undefined, headline: fields.title, image: img !== null && img !== void 0 && (_img$images = img.images) !== null && _img$images !== void 0 && (_img$images$fallback = _img$images.fallback) !== null && _img$images$fallback !== void 0 && _img$images$fallback.src ? "" + siteUrl + (img === null || img === void 0 ? void 0 : (_img$images2 = img.images) === null || _img$images2 === void 0 ? void 0 : (_img$images2$fallback = _img$images2.fallback) === null || _img$images2$fallback === void 0 ? void 0 : _img$images2$fallback.src) : undefined, datePublished: new Date(fields.date).toISOString() }; };
export var makeBlogPosting = function makeBlogPosting(siteUrl, data) { return _objectSpread(_objectSpread({}, makeArticle(siteUrl, data)), {}, { '@type': "BlogPosting" }); };
export var makeBlog = function makeBlog(siteUrl, subPage, articles) { return { '@type': "Blog", blogPost: articles.map(function (article) { return makeBlogPosting(siteUrl, article); }), mainEntityOfPage: { '@type': "WebPage", url: siteUrl + "/" + subPage }, author: _toConsumableArray(new Set(_toConsumableArray(articles.map(function (_ref3) { var frontmatter = _ref3.frontmatter; return jsonStringifySorted((frontmatter === null || frontmatter === void 0 ? void 0 : frontmatter.author) || ""); }))).values()).filter(function (s) { return s !== ""; }).map(function (s) { var d = JSON.parse(s); return makePerson(d); }) }; };
var walk = function walk(n, f, key) { if (key === void 0) {
    key = "_";
} if (typeof n === "object") {
    if ("@type" in n) {
        f(key, n);
    }
    Object.entries(n).forEach(function (_ref4) { var k = _ref4[0], v = _ref4[1]; return walk(v, f, key + "." + k); });
}
else if (Array.isArray(n)) {
    n.forEach(function (v, i) { return walk(v, f, key + "." + i); });
} };
export var makeRoot = function makeRoot(root) {
    var fullData = _objectSpread({ '@context': "http://schema.org/" }, root);
    var seenTimes = {};
    walk(fullData, function (k, n) { if (k !== "_") {
        var v = jsonStringifySorted(n);
        seenTimes[v] = (seenTimes[v] || 0) + 1;
    } });
    var currentId = 0;
    var ids = {};
    walk(fullData, function (k, n) {
        if (k !== "_") {
            var v = jsonStringifySorted(n);
            if (seenTimes[v] > 1) {
                if (!(v in ids)) {
                    ids[v] = "" + k + currentId;
                    currentId += 1; // eslint-disable-next-line no-param-reassign
                    n["@id"] = ids[v];
                }
                else { // eslint-disable-next-line no-param-reassign
                    Object.keys(n).forEach(function (key) { return delete n[key]; }); // eslint-disable-next-line no-param-reassign
                    n["@id"] = ids[v];
                }
            }
        }
    });
    return fullData;
};
